import * as React from "react";
import ImagePreview from "../_components/ImagePreview/ImagePreview";

const MapPreview = () => {
    return (
        <div className="map">
            <ImagePreview
                src={'https://download.xela.co/glide/gla/glide-london.2024.jpg'}
            />
        </div>
    );
};

export default MapPreview;
